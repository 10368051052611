<template>
  <div>
    <b-button v-b-modal.createPredictionModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-success"
      block>
      สร้างกิจกรรม
    </b-button>

    <b-modal v-model="modal" size="xl" id="createPredictionModal" title="สร้างกิจกรรม">
      <div class="h4 text-info">เลือกคู่ที่ท่านต้องการ 2 คู่</div>

      <b-form-group label="ประจำวันที่:" label-for="date">
        <flat-pickr id="date" v-model="date" class="form-control" :config="{ onChange: dateChange }" />
      </b-form-group>

      <b-form-checkbox-group v-if="competitions.length" v-model="matches">
        <div class="row px-1">
          <b-list-group class="col-6 mb-1" v-for="competition in competitions">
            {{ competition.name }}
            <b-list-group-item v-for="(match, index) in competition.matches" :name="`match${index + 1}`"
              :disabled="match.status === 'POSTPONED'">
              <div class="d-flex flex-row">
                <b-form-checkbox :disabled="match.status === 'POSTPONED'"
                  :value="match.footballDataMatchId"></b-form-checkbox>
                <div class="d-flex flex-row ml-4">
                  <div class="mr-2">
                    <b-badge v-if="match.status === 'POSTPONED'" pill variant="danger">เลื่อน</b-badge>
                    <span v-else>
                      {{ match.startDateUTC | convertTime }}
                    </span>
                  </div>
                  <div>
                    <img :src="match.homeTeam.crest" alt="Home" width="25" height="25">
                    {{ match.homeTeam.shortName }}
                  </div>
                  <div class="px-2">Vs</div>
                  <div>
                    {{ match.awayTeam.shortName }}
                    <img :src="match.awayTeam.crest" alt="Home" width="25" height="25">
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div class="col-6 row">
            <div class="col-4">
              <b-form-group label="เงินรางวัล (บาท):" label-for="reward">
                <b-form-input id="reward" v-model="reward" type="number" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="Chip ที่ต้องใช้:" label-for="chip-required">
                <b-form-input disabled id="chip-required" v-model="chip_required" type="number" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="ปิดการทายผลเวลา:" label-for="close_at">
                <flat-pickr id="close_at" v-model="close_at" :config="closeAtConfig" class="form-control" />
              </b-form-group>
            </div>
          </div>
        </div>
      </b-form-checkbox-group>

      <b-alert v-else class="p-2" show variant="warning">
        <h4 class="h3 text-warning">ไม่พบข้อมูลการแข่งขัน</h4>
        <p>ระบบจะดึงข้อมูลการแข่งขันล่วงหน้า 10 วัน อัตโนมัติทุกวันเวลา 08.00 น.</p>

      </b-alert>

      <template #modal-footer>
        <b-button :disabled="matches.length !== 2" variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  props: ['competitions'],
  emits: ['changeDate'],
  filters: {
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  data: () => ({
    modal: false,
    matches: [],
    reward: 1000,
    chip_required: 1,
    date: new Date(),
    close_at: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), 0),
    closeAtConfig: {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
    },
    dateConfig: {
      // onChange: this.x().bind(this)
    }
  }),
  methods: {
    dateChange(selectedDates, dateStr, instance) {
      this.$emit('changeDate', dateStr)
    },
    submit() {
      this.$emit('submit', {
        matches: this.matches,
        reward: this.reward,
        date: this.date,
        close_at: this.close_at
      })

      this.matches = [];
      this.reward = 1000;
      this.date = new Date();
      this.close_at = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), 0);

      this.modal = false
    },
    cancel() {
      this.modal = false;

      this.matches = [];
      this.reward = 1000;
      this.date = new Date();
      this.close_at = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), 0);
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>